import { observable, makeAutoObservable, action} from 'mobx';
import WebApi from '@/web/api';

export default class PurchaseOrderDetailMobx {

  private purchaseOrderId = '';

  purchaseId: string = ''
  activeKey: string =  '1'
  loading: boolean =  true
  createdAt: string =  ''
  orderBy: string = ''
  supplierName: string = ''
  supplierId: string = ''
  orderStatus: string = ''
  paymentStatus: string =  ''
  orderStatusDesc: string = ''
  paymentStatusDesc: string = ''
  remark: string = ''
  totalAmount: string = ''
  productKind: string = ''
  productCount: number = 0

  @observable detailList: any[] = []
  @observable inStockRecordList: any[] = []
  @observable paymentRecordList: any[] = []

  constructor(id: string) {
    makeAutoObservable(this);
    this.purchaseOrderId = id;
  }

  @action
  findDetail() {
    this.loading = true;
    WebApi.purchase_order_detail({
      purchaseOrderId: this.purchaseOrderId,
    })
    .then((data: any) => {
      this.loading = false;
      this.purchaseId = data.purchaseId;
      this.orderStatus = data.orderStatus;
      this.orderStatusDesc = data.orderStatusDesc,
      this.supplierId = data.supplierId;
      this.paymentStatus = data.paymentStatus;
      this.paymentStatusDesc = data.paymentStatusDesc;
      this.supplierName = data.supplierName;
      this.remark = data.remark;
      this.detailList = data.detailList;
      this.totalAmount = data.totalAmount;
      this. productKind = data.productKind;
      this.productCount = data.productCount;
      this.createdAt = data.createdAt;
      this.inStockRecordList = data.inStockRecordList;
      this.paymentRecordList = data.paymentRecordList;
    })
  }
}