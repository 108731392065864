import React from 'react';
import { observer } from 'mobx-react';
import PurchaseDetailMobx from './PurchaseOrderDetailMobx';
import { Dialog, BookOrderTable, Button, Layout, ViewTable, BookCreateDialog, InStockDialog } from '@/components';
import { LibAuthCode } from 'lib/Auth';
import LibEnum from 'lib/enum';
import WebApi from '@/web/api';
import { hideLoading, showLoading } from '@/components/message/message';

interface IState {
  inStockDialogVisible: boolean
}
@observer
export default class PurchaseDetailPage extends React.PureComponent<RouterPropsType, IState> {

  private purchaseDetailMobx = new PurchaseDetailMobx(this.props.match?.params.id);

  private bookCreateDialog = React.createRef<BookCreateDialog>()

  constructor(props) {
    super(props);
    this.state = {
      inStockDialogVisible: false
    }
  }

  componentDidMount() {
    this.purchaseDetailMobx.findDetail();
  }

  render() {

    return <Layout.Page flexColumn overHidden loading={this.purchaseDetailMobx.loading}>
      <Layout.Toolbar title="进货单详情">
        {
          this.purchaseDetailMobx.orderStatus === LibEnum.PURCHASE_ORDER_STATUS.WAIT_AUDIT.value &&
          <Button authCode={LibAuthCode.purchaseOrder.update} type="primary" style={{marginLeft: 20}} onClick={this.clickAudit}>审核通过</Button>
        }
        {
          this.purchaseDetailMobx.orderStatus === LibEnum.PURCHASE_ORDER_STATUS.WAIT_IN_STOCK.value  &&
          <Button type="primary" authCode={LibAuthCode.purchaseOrder.update} onClick={() => {
            this.setState({
              inStockDialogVisible: true
            });
          }}>商品入库</Button>
        }
        {
          this.purchaseDetailMobx.paymentStatus === LibEnum.PAYMENT_STATUS_2.WAIT_PAYMENT.value  &&
          <Button.Create
            authCode={LibAuthCode.purchaseOrder.update}
            onClick={() => this.bookCreateDialog.current?.open()}>添加付款记录</Button.Create>
        }
        {
          this.purchaseDetailMobx.orderStatus === LibEnum.PURCHASE_ORDER_STATUS.WAIT_AUDIT.value &&
          <Button type="default" authCode={LibAuthCode.purchaseOrder.update} icon={<i className="iconfont icon-zuofei"/>} onClick={this.clickCancel}>作废</Button>
        }
        {
          this.purchaseDetailMobx.orderStatus === LibEnum.PURCHASE_ORDER_STATUS.INVALID.value && this.purchaseDetailMobx.paymentStatus === LibEnum.PAYMENT_STATUS_2.WAIT_PAYMENT.value &&
          <Button.Delete authCode={LibAuthCode.purchaseOrder.delete} onClick={this.clickDelete}>删除</Button.Delete>
        }
      </Layout.Toolbar>
      <Layout flex1 scrollY>
        <Layout.Title>订单信息</Layout.Title>
        <div>
          <p style={{fontSize: 18, color: 'red'}}>
            <span style={{display: 'inline-block', width: 400}}>
            {this.purchaseDetailMobx.orderStatusDesc}
            </span>
            {this.purchaseDetailMobx.paymentStatusDesc}
          </p>
          <p>
            <span style={{display: 'inline-block', width: 400}}>
              <strong style={{marginRight: 10}}>进货单号：</strong>
              {this.purchaseDetailMobx.purchaseId}
            </span>
            <strong style={{marginRight: 10}}>下单时间：</strong>{this.purchaseDetailMobx.createdAt}
          </p>
          <p>
            <span style={{display: 'inline-block', width: 412}}>
              <strong style={{marginRight: 10}}>订单金额：</strong>{this.purchaseDetailMobx.totalAmount}
            </span>
            <strong style={{marginRight: 10}}>供应商：</strong>
            {this.purchaseDetailMobx.supplierName}
          </p>
          <p>
            <strong style={{marginRight: 10}}>备注说明：</strong>
            {this.purchaseDetailMobx.remark}
          </p>
        </div>

        <Layout.Title icon="icon-form-shangpin" style={{marginTop: 30}}>商品明细</Layout.Title>
        <ViewTable
          flex1
          pagination={false}
          dataSource={this.purchaseDetailMobx.detailList}
          loading={this.purchaseDetailMobx.loading}
          rowKey={row => row.purchaseDetailId}
          columns={detailColumns as any}/>

        <Layout.Title icon="icon-ruku" style={{marginTop: 30}}>入库记录</Layout.Title>
        <ViewTable
          flex1
          pagination={false}
          locale={{
            emptyText: '暂无记录'
          }}
          dataSource={this.purchaseDetailMobx.inStockRecordList}
          rowKey={row => row.purchaseInStockId}
          // scroll={{x: true}}
          columns={inStockColumns as any}
        />

        {
          this.state.inStockDialogVisible &&
          <InStockDialog
            visible={this.state.inStockDialogVisible}
            onCancel={() => this.setState({inStockDialogVisible: false})}
            dataSource={this.purchaseDetailMobx.detailList.map(i => {
              return {
                ...i,
                supplierId: this.purchaseDetailMobx.supplierId,
                supplierName: this.purchaseDetailMobx.supplierName,
                purchasePrice: i.productPrice,
                count: i.productCount
              };
            })}
            onOk={this.clickInStockOk}
          />
        }

        <Layout.Title icon="icon-fukuanjilu" style={{marginTop: 30}}>付款记录</Layout.Title>
        <BookOrderTable
          mode="payment"
          dataSource={this.purchaseDetailMobx.paymentRecordList}
        />
        <BookCreateDialog ref={this.bookCreateDialog} actualAmount={this.purchaseDetailMobx.totalAmount} shouldAmount={this.purchaseDetailMobx.totalAmount} onOk={this.clickCreatePaymentRecord}/>
      </Layout>
    </Layout.Page>
  }

  // 审核订单
  private clickAudit = async () => {
    Dialog.confirm({
      content: '确定审核通过吗?',
      onOk: async () => {
        WebApi.purchase_order_audit({
          purchaseOrderId: this.props.match?.params.id,
        })
        .then(() => {
          showSuccess.save();
          this.purchaseDetailMobx.findDetail();
        });
      }
    });
  }

  // 作废订单
  private clickCancel = async () => {
    Dialog.confirm({
      content: '确定作废进货单吗?',
      onOk: async () => {
        WebApi.purchase_order_cancel({
          purchaseOrderId: this.props.match?.params.id,
        })
        .then(() => {
          showSuccess.save();
          this.purchaseDetailMobx.findDetail();
        });
      }
    });
  }

  // 删除订单
  private clickDelete = async () => {
    Dialog.confirm({
      title: '删除确认',
      content: '确定删除进货单吗?',
      onOk: async () => {
        WebApi.purchase_order_delete({
          purchaseOrderId: this.props.match?.params.id,
        })
        .then(() => {
          showSuccess.save();
          this.props.history.close();
        })
      }
    });
  }

  // 入库
  private clickInStockOk = (data) => {
    return WebApi.purchase_order_in_stock({
      purchaseOrderId: this.props.match?.params.id,
      detailList: data.map(i => {
        return {
          purchaseOrderDetailId: i.id,
          repositoryId: i.repositoryId,
        };
      })
    })
  }

  private clickCreatePaymentRecord = (data) => {
    showLoading(1);
    WebApi.purchase_order_pay({
      purchaseOrderId: this.props.match?.params.id,
      ...data
    })
    .then(() => {
      showSuccess.save();
      this.purchaseDetailMobx.findDetail()
    }).finally(() => {
      hideLoading();
    })
  }
}

const detailColumns = [
  {
    title: '商品',
    dataIndex: 'productName',
    width: 200,
  },
  {
    title: '规格',
    dataIndex: 'skuName',
    width: 300,
  },
  {
    title: '进价',
    align: 'right',
    dataIndex: 'productPrice',
    width: 100,
  },
  {
    title: '数量',
    align: 'right',
    dataIndex: 'productCount',
    width: 100,
  },
  {
    title: '金额小计',
    align: 'right',
    dataIndex: 'totalAmount',
    width: 100,
  },
  {
    title: '备注',
    titleAlign: 'left',
    dataIndex: 'remark',
    width: 'auto',
  },
]

const inStockColumns = [
  {
    title: '商品',
    dataIndex: 'productName',
    width: 200,
  },
  {
    title: '规格',
    dataIndex: 'skuName',
    width: 300,
  },
  {
    title: '进价',
    align: 'right',
    dataIndex: 'price',
    width: 100,
  },
  {
    title: '数量',
    align: 'right',
    dataIndex: 'count',
    width: 100,
  },
  {
    title: '仓库',
    dataIndex: 'repositoryName',
    width: 200,
  },
  {
    title: '时间',
    align: 'center',
    dataIndex: 'createdAt',
    width: 'auto',
  },
]